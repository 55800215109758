import React, { useEffect, useState } from 'react';
import Button from '~components/button';
import Layout from '~components/layout';
import Typography from '~components/typography/typography';
import { Router, useTranslation } from '~i18n';
import { PasswordSuccess } from '~svg-components';
import styles from './reset-success.module.scss';

const countdown = 60;

const ResetSuccess: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const { t } = useTranslation('student_portal');
  const [count, setCount] = useState(countdown);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (count > 0) {
        setCount((c: number) => c - 1);
      } else {
        Router.push('/login');
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [count]);

  return (
    <Layout className={styles.root}>
      <PasswordSuccess className={styles.icon} />
      <Typography component="div" textAlign="center" variant="h4">
        {t('reset_password.success.title')}
      </Typography>
      <Typography component="div" variant="body1" className={styles.description}>
        {t('reset_password.success.description', {
          count,
        })}
      </Typography>
      <Button
        className={styles.submit}
        onClick={() => {
          Router.push('/login');
        }}
        type="submit"
        size="large"
      >
        {t('auth.login')}
      </Button>
    </Layout>
  );
};

export default ResetSuccess;
