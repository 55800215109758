import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { NextPage } from 'next';
import React, { useEffect, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import AuthContainer from '~components/auth';
import Button from '~components/button';
import { Input } from '~components/form';
import { useThemeContext } from '~components/theme-provider';
import Typography from '~components/typography';
import { Authorization } from '~constants/cookie-properties';
import { SP_StudentLogin, SP_StudentLoginVariables } from '~graphql/__generated__/SP_StudentLogin';
import { STUDENT_LOGIN } from '~graphql/user';
import { QUERY_STUDENT_ID } from '~graphql/user';
import { Link, Router, Trans, useTranslation } from '~i18n';
import styles from '~public/styles/login.module.scss';
import { getCookie, setCookie } from '~utils/cookie-operation';
import { gtmDataLayer } from '~utils/use-checkout-stage';
interface Values {
  email: string;
  password: string;
}

type SetError = {
  (field: string, message: string): void;
};

type SP_Student_ID = {
  studentViewer: {
    studentInfo: {
      originId: number | null;
    };
  };
};

const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
const STUDENT_USER_NOT_FOUND = 'STUDENT_USER_NOT_FOUND';
const Login: NextPage = () => {
  const { t } = useTranslation(['student_portal', 'public']);
  const { topLandlordId } = useThemeContext();

  useEffect(() => {
    const authToken = getCookie(Authorization);
    if (authToken) {
      Router.replace('/my-bookings');
    }
  }, []);
  const emailValidError = t('form.validation.format_error', {
    field_name: t('form.field.email_address').toLowerCase(),
  });
  const validError = t('form.validation.password');
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(emailValidError)
      .required(),
    password: Yup.string()
      .trim()
      .min(8, validError)
      .matches(/(?=.*[a-zA-Z])(?=.*[0-9])./, validError)
      .required(),
  });

  const setFieldErrorFuc = useRef<SetError | null>(null);
  const submitCountRef = useRef<number>(0);
  const [studentViewer, { loading }] = useLazyQuery<SP_Student_ID>(QUERY_STUDENT_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const {
        studentViewer: {
          studentInfo: { originId },
        },
      } = data;
      if (originId) {
        gtmDataLayer({ event: 'login', userId: originId });
      }
      const redirectUrl = localStorage.getItem('redirectAfterLogin') || '';
      if (redirectUrl) {
        localStorage.removeItem('redirectAfterLogin');
        window.location.href = redirectUrl;
      } else {
        Router.replace('/my-bookings');
      }
    },
  });
  const [studentLogin, { error }] = useMutation<SP_StudentLogin, SP_StudentLoginVariables>(
    STUDENT_LOGIN,
    {
      onCompleted: data => {
        const token = data.studentLogin?.authToken;
        if (token) {
          setCookie(Authorization, `Bearer ${token}`, 30);
          studentViewer();
        }
      },
      onError: ({ graphQLErrors }) => {
        const { message } = graphQLErrors?.[0] || {};
        if (message === STUDENT_USER_NOT_FOUND) {
          setFieldErrorFuc.current!('email', t('form.validation.user_not_find'));
        }
        if (message === INVALID_CREDENTIALS) {
          setFieldErrorFuc.current!('password', t('form.validation.password_wrong_text'));
        }
      },
    }
  );

  const emailErrorComponent = useMemo(() => {
    if (error && error.message.includes(STUDENT_USER_NOT_FOUND)) {
      return (
        <Trans i18nKey="form.validation.user_not_find">
          No account found for this email.
          <Typography
            color="var(--color-red_6)"
            variant="body3"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => {
              Router.push('/create-account');
            }}
          >
            Create an account now?
          </Typography>
        </Trans>
      );
    }
    return null;
  }, [error]);

  const passwordErrorComponent = useMemo(() => {
    if (error && error.message.includes(INVALID_CREDENTIALS) && submitCountRef.current > 2) {
      return (
        <Trans i18nKey="form.validation.password_wrong">
          The password you entered is incorrect.
          <Typography
            color="var(--color-red_6)"
            variant="body3"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => {
              Router.push('/forgot-password');
            }}
          >
            {{ resetText: t('auth.lost_password') }}
          </Typography>
        </Trans>
      );
    }
    return null;
  }, [error, t]);

  const onSubmit = async (values: Values, { setFieldError }: FormikHelpers<Values>) => {
    setFieldErrorFuc.current = setFieldError;
    studentLogin({
      variables: {
        ...values,
        topLandlordId,
      },
    });
  };

  const loginForm = ({ submitCount }: FormikProps<Values>) => {
    submitCountRef.current = submitCount;
    return (
      <Form className={styles.loginForm}>
        <Input
          name="email"
          type="text"
          errorComponent={emailErrorComponent}
          className={styles.loginInput}
          label={t('form.field.email_address')}
          placeholder={t('form.field.email_address')}
        />
        <Input
          name="password"
          className={styles.loginInput}
          type="password"
          errorComponent={passwordErrorComponent}
          label={t('form.field.password')}
          labelRight={
            <Link href="/forgot-password">
              <Typography variant="body3" cursor="pointer" textDecoration="underline">
                {t('login.forgot_password')}
              </Typography>
            </Link>
          }
          placeholder={t('form.field.password')}
        />
        <Button loading={loading} size="large" className={styles.loginSubmit} type="submit">
          {t('auth.login')}
        </Button>
      </Form>
    );
  };

  return (
    <AuthContainer type="login">
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={loginSchema}
        component={loginForm}
      />
    </AuthContainer>
  );
};

Login.getInitialProps = async () => ({
  namespacesRequired: ['public', 'student_portal'],
});

export default Login;
