import { UAContext, UserAgent } from '@quentin-sommer/react-useragent';
import cn from 'classnames';
import getConfig from 'next/config';
import React, { useContext } from 'react';
import Divider from '~components/divider';
import Layout from '~components/layout';
import { OrgOptions, useThemeContext } from '~components/theme-provider';
import Typography from '~components/typography/typography';
import { Link, useTranslation } from '~i18n';
import { ColivingLogo, UreLogo, YugoLogo } from '~svg-components';
import styles from './container.module.scss';
const { publicRuntimeConfig } = getConfig();

interface AuthContainerProps {
  type?: 'login' | 'register';
}

const AuthContainer: React.FC<AuthContainerProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  type,
}) => {
  const { t } = useTranslation('student_portal');
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const isLogin = type === 'login';
  const { ORG } = useThemeContext();

  const renderLoginTitle = () =>
    isLogin && (
      <>
        <Typography
          component="p"
          fullWidth
          textAlign="center"
          variant="h3"
          color="var(--color-white)"
          className={styles.welcome}
        >
          {t('login.title')}
        </Typography>

        <Typography
          component="p"
          color="var(--color-white)"
          variant="body1"
          textAlign="center"
          fullWidth
        >
          {t('login.label')}
        </Typography>
      </>
    );

  const renderRegister = () =>
    !isLogin && (
      <div className={styles.description}>
        <p>{t(`create_account.label1.${ORG}`)}</p>
        <p>{t('create_account.label2')}</p>
      </div>
    );

  return (
    <Layout noNeedHeaderFooter>
      <div className={styles.container}>
        <UserAgent computer tablet>
          <div
            className={styles.container_left}
            style={{
              backgroundImage: `url(${publicRuntimeConfig.PUBLIC_FILE_PATH_PREFIX}/static/imgs/${
                isLogin ? 'login' : 'create-account'
              }-bg.jpg)`,
            }}
          >
            {renderLoginTitle()}
            {renderRegister()}

            <div className={cn(styles.logo, { [styles.logoRegister]: !isLogin })}>
              {ORG === OrgOptions.YUGO && <YugoLogo width="80" height="80" />}
              {ORG === OrgOptions.URE && <UreLogo width="130" height="42" />}
              {ORG === OrgOptions.COLIVING && <ColivingLogo width="130" height="42" />}
            </div>
          </div>
        </UserAgent>
        <div className={styles.container_right}>
          <div
            className={cn(styles.wrapper, {
              [styles.wrapper_login]: isLogin,
              [styles.wrapper_register]: !isLogin,
            })}
          >
            <Typography variant={isMobile ? 'meta' : 'body2'} style={{ marginRight: 8 }}>
              {isLogin ? t('auth.not_have_account') : t('auth.have_account')}
            </Typography>
            <Link href={isLogin ? '/create-account' : '/login'}>
              <Typography
                textDecoration="underline"
                variant={isMobile ? 'meta' : 'body2'}
                cursor="pointer"
              >
                {isLogin ? t('auth.create_account') : t('auth.login')}
              </Typography>
            </Link>
          </div>
          <div className={styles.title}>
            <Typography component="p" fullWidth variant={isMobile ? 'h7' : 'h2'}>
              {isLogin ? t('auth.login') : t('auth.create_account')}
            </Typography>
            <Typography
              component="p"
              fullWidth
              variant={isMobile ? 'body3' : 'body2'}
              className={styles.auth_description}
            >
              {isLogin ? t(`login.welcome.${ORG}`) : t(`create_account.description.${ORG}`)}
            </Typography>
            <Divider />
          </div>
          {children}
        </div>
        <UserAgent mobile>
          <div className={cn(styles.logoMobile, { [styles.logoMobileFixed]: isLogin })}>
            {ORG === OrgOptions.YUGO && <YugoLogo width="80" height="80" />}
            {ORG === OrgOptions.URE && <UreLogo width="130" height="42" />}
            {ORG === OrgOptions.COLIVING && <ColivingLogo width="130" height="42" />}
          </div>
        </UserAgent>
      </div>
    </Layout>
  );
};

export default AuthContainer;
